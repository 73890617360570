<template>
  <div class="loading" v-if="isLoading">加载中，请稍后...</div>
  <div class="prescription-wrapper" v-else>
    <div class="title">试戴片参数和推荐的强生安视优产品</div>
    <!-- 右眼 -->
    <div class="prescription" v-if="eye === 'Right' || eye === 'All'">
      <div class="left" ref="eyeButtonRef" :style="eyeButtonStyle">右眼</div>
      <div class="right">
        <div class="sphere">球镜：{{ prescription.rightSphere }}</div>
        <div class="divider"></div>
        <div class="cylinder">柱镜：{{ prescription.rightCylinder }}</div>
        <div class="divider"></div>
        <div class="axis">轴位：{{ prescription.rightAxis }}°</div>
      </div>
    </div>
    <div class="product-item" v-if="productCount == 2">
      <div class="product-bg"><img :src="productUrl1" /></div>
    </div>
    <div class="product-name" v-if="productCount == 2">{{ productName1 }}</div>
    <!-- 左眼 -->
    <div class="prescription" v-if="eye === 'Left' || eye === 'All'">
      <div class="left" ref="eyeButtonRef" :style="eyeButtonStyle">左眼</div>
      <div class="right">
        <div class="sphere">球镜：{{ prescription.leftSphere }}</div>
        <div class="divider"></div>
        <div class="cylinder">柱镜：{{ prescription.leftCylinder }}</div>
        <div class="divider"></div>
        <div class="axis">轴位：{{ prescription.leftAxis }}°</div>
      </div>
    </div>
    <div class="product-item">
      <div class="product-bg"><img :src="productUrl2" /></div>
    </div>
    <div class="product-name">{{ productName2 }}</div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import axios from "axios";
import { getEvenPx } from "@/js/getEvenPx";

import { useRouter } from "vue-router";
import { useStore } from "vuex";

const props = defineProps({});
const emits = defineEmits([]);

const eyeButtonRef = ref(null);

const productName1 = ref("");
const productUrl1 = ref("");
const productName2 = ref("");
const productUrl2 = ref("");
const prescription = ref({});
const eye = ref("");
const productCount = ref(1);
const isLoading = ref(true);

const eyeButtonStyle = computed(() => {
  let style = {};
  if (eyeButtonStyle.value) {
    style.height = getEvenPx(eyeButtonStyle.value.clientHeight) + "px";
  }
  return style;
});

onMounted(() => {
  isLoading.value = true;
  const productId = JSON.parse(
    window.location.href.split("?")[1].split("&")[0].split("=")[1]
  );
  // 标识是两个不同的产品，还是一个产品
  if (productId[0] && productId[1]) {
    productCount.value = 2;
  } else {
    productCount.value = 1;
  }

  prescription.value.leftSphere = window.location.href
    .split("?")[1]
    .split("&")[1]
    .split("=")[1];
  prescription.value.rightSphere = window.location.href
    .split("?")[1]
    .split("&")[2]
    .split("=")[1];
  prescription.value.leftCylinder = window.location.href
    .split("?")[1]
    .split("&")[3]
    .split("=")[1];
  prescription.value.rightCylinder = window.location.href
    .split("?")[1]
    .split("&")[4]
    .split("=")[1];
  prescription.value.leftAxis = window.location.href
    .split("?")[1]
    .split("&")[5]
    .split("=")[1];
  prescription.value.rightAxis = window.location.href
    .split("?")[1]
    .split("&")[6]
    .split("=")[1];
  eye.value = window.location.href.split("?")[1].split("&")[7].split("=")[1];

  axios({
    url: "/api/Widgets/query_product_by_id?authcode=CN20230209ASD43ADSCADZ",
    method: "post",
    data: productId,
  }).then(res => {
    const productList = res.data.data;
    console.log(productList.length);
    if (productList.length == 2) {
      // 如果散光非散光都有，才有两个不同的产品，需要显示两个
      // 非散光判断依据: 柱镜 > -0.75
      console.log(prescription.value.rightCylinder);
      productList.forEach((item, index) => {
        if (prescription.value.rightCylinder > -0.75) {
          if (!item.isAstig) {
            productName1.value = item.productName;
            productUrl1.value = item.productUrl;
          } else {
            productName2.value = item.productName;
            productUrl2.value = item.productUrl;
          }
        } else {
          if (!item.isAstig) {
            productName2.value = item.productName;
            productUrl2.value = item.productUrl;
          } else {
            productName1.value = item.productName;
            productUrl1.value = item.productUrl;
          }
        }
      });
    } else {
      productName2.value = productList[0].productName;
      productUrl2.value = productList[0].productUrl;
    }
    console.log(productName2.value);
    isLoading.value = false;
  });
});
</script>

<style lang="scss" scoped>
@import "@/style/constants.scss";

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: $bg-color;
  font-size: 1.5rem;
  color: $primary-color;
}

.prescription-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: $bg-color;
  .title {
    margin-bottom: 2.5rem;
    max-width: 90%;
    text-align: center;
    font-size: 2rem;
    letter-spacing: 0.1rem;
    color: $primary-color;
    font-family: sourcehansans_medium;
  }
  .prescription {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    padding: 0.7rem 0.7rem;
    border: 0.1rem solid $border-color;
    border-radius: 0.8rem;
    background-color: #fff;
    font-size: 1.2rem;
    .left {
      display: flex;
      align-items: center;
      margin-right: 0.5rem;
      padding: 0 0.7rem;
      height: 2.2rem;
      background-color: $primary-color;
      color: #fff;
      font-family: sourcehansans_regular;
      border-radius: 0.4rem;
    }
    .right {
      display: flex;
      align-items: center;
      width: 25rem;
      color: $main-text-color;
      font-family: sourcehansans_regular;
      .sphere {
        display: flex;
        justify-content: center;
        flex: 1;
      }
      .cylinder {
        display: flex;
        justify-content: center;
        flex: 1;
      }
      .axis {
        display: flex;
        justify-content: center;
        flex: 1;
      }
      .divider {
        width: 0.1rem;
        height: 1.6rem;
        background-color: #e5f5fb;
      }
    }
  }

  .product-img {
    margin-top: 2rem;
    width: 19rem;
    border-radius: 5vw;
    border: 0.2rem solid $border-color;
  }

  .product-item {
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    .product-bg {
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0.5rem;
      width: 19rem;
      height: calc(19rem / 1.1);
      border-radius: 4vw;
      border: 0.1rem solid $border-color;
      img {
        width: 19rem;
      }
    }
  }
}
.product-name {
  margin-bottom: 2.5rem;
  max-width: 18rem;
  text-align: center;
  font-size: 1.7rem;
  letter-spacing: 0.1rem;
  color: #333;
  font-family: sourcehansans_normal;
}
</style>
